import React from "react"
import Layout from '../components/layout'; 
import aboutStyles from '../styles/about.module.scss'
import image from '../images/vector-image.jpg';
import image2 from '../images/vector-image-2.jpg';

const AboutPage = () => {
    return(
        <Layout>
            <div className={aboutStyles.contenido1}>
                <h1 className={aboutStyles.titulo}>Nosotros</h1>
                <h3 className={aboutStyles.resumen}>Somos una empresa especializada en el levantamiento, análisis y medición de información relativa al riesgo laboral, comercial y de crédito. Con mas de 25 años de experiencia operativa.</h3>
                <img className={aboutStyles.image} src={image} alt="imagen 1" />
                <div className={aboutStyles.row}>
                    <div className={aboutStyles.column}>
                        <h4>Misión</h4>
                        <p className={aboutStyles.textColumn}>Ofrecer soluciones integrales de información clave de personas y empresas para la evaluación y toma del riesgo, plenamente humana y ética, enfocada a nuestros clientes y aliados comerciales.</p>
                    </div>
                    <div className={aboutStyles.column}>
                        <h4>Visión</h4>
                        <p className={aboutStyles.textColumn}>Ser reconocidos por nuestros clientes como el socio estratégico en desarrollo de soluciones de servicio.</p>
                    </div>
                    <div className={aboutStyles.column}>
                        <h4>Valores</h4>
                        <ul className={aboutStyles.bullets}>
                            <li>Respeto por la gente.</li>
                            <li>Conocimiento del mercado.</li>
                            <li>Enfoque al cliente.</li>
                            <li>Rapidez, simplicidad y agilidad.</li>
                            <li>Trabajo en equipo.</li>
                            <li>Escuchar al cliente.</li>
                            <li>Exceder las expectativas del cliente.</li>
                            <li>Exigir calidad.</li>
                        </ul>
                    </div>
                </div>
                <div className={aboutStyles.row}>
                    <div className={aboutStyles.column}>
                        <h4>Código de Ética</h4>
                        <p className={aboutStyles.descriptionText}>Control Integral es un grupo de profesionales convencidos de la importancia del cambio y de la necesidad de responder de forma oportuna a las demandas del mercado, de nuestros clientes, colaboradores/a y socios. El éxito fundamental de nuestra empresa la basamos en el respeto y desarrollo integral de los/as colaboradores/as, la integridad, trabajo en equipo y progreso, así como la pasión por el servicio al cliente y la creación de valor social. Estos valores dan como resultado la confianza en Control Integral y en los /las que formamos parte de esta empresa.</p>
                    </div>
                </div>
                <img className={aboutStyles.image2} src={image2} alt="imagen 2" />
                <div className={aboutStyles.row}>
                    <div className={aboutStyles.column}>
                        <h4>Políticas Anticorrupción</h4>
                        <p className={aboutStyles.textColumn}>Control Integral tiene una política que prohíbe la realización, el ocultamiento u otra actividad tendiente a llevar a cabo actos de soborno, corrupción, colusión, cohecho o en general cualquier actividad que implique un pago económico o en especie, ventajas, privilegios o préstamo de servicios ya sea de manera directa o por medio de terceros, actividades realizadas con el objetivo de evitar el incumplimiento de una disposición de carácter legal, administrativa o judicial.</p>
                    </div>
                    <div className={aboutStyles.column}>
                        <h4>Información Confidencial</h4>
                        <p className={aboutStyles.textColumn}>En el equipo de Control Integral, la confianza es de suma importancia, con la cual también asumimos el compromiso de cuidar de malos manejos la información confidencial y sensible como lo marca la ley y de acuerdo a nuestro Aviso de Privacidad y manejo de datos personales.</p>
                    </div>
                    <div className={aboutStyles.column}>
                        <h4>Derechos Humanos</h4>
                        <p className={aboutStyles.textColumn2}> Control Integral reconoce los derechos humanos en todas sus operaciones y no acepta laborar o colaborar con empresas que tengan prácticas tales como:</p>
                        <ul className={aboutStyles.bullets2}>
                            <li>Trabajo infantil.</li>
                            <li>Cualquier forma de abuso humano.</li>
                            <li>Trabajo forzado u obligatorio.</li>
                            <li>Discriminación en el empleo y en la ocupación.</li>
                            <li>Acoso de cualquier tipo y/o de cualquier otra conducta ofensiva o irrespetuosa.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    ) 
}
export default AboutPage;
